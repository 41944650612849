import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles({
  name: "authGuard",
})((theme) => {
  return {
    loading: {
      width: "100vw",
      height: "100vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  };
});

export default useStyles;
