import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles({
  name: "appToolbar",
})((theme) => {
  return {
    root: {
      width: "100%",
      position: "relative",
      [theme.breakpoints.up("xs")]: {
        height: 90,
      },
      [theme.breakpoints.up("sm")]: {
        height: 90,
      },
      [theme.breakpoints.up("md")]: {
        height: 150,
      },
    },
  };
});

export default useStyles;
