import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles({
  name: "notfound404",
})((theme) => {
  return {
    main: {
      padding: theme.spacing(5, 0),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  };
});

export default useStyles;
