import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles({
  name: "appIconButton",
})((theme) => {
  return {
    root: {
      backgroundColor: theme.palette.common.white,
    },
    boxShadowMenuPopper: {
      boxShadow: theme.app.shadows[0],
    },
  };
});

export default useStyles;
