import { useState, useRef } from "react";

import {
  Box,
  ButtonBase,
  ClickAwayListener,
  Grow,
  Popper,
  Typography,
} from "@mui/material";
import AppSvgIcon from "@/components/AppSvgIcon";
import AppPaper from "@/components/AppPaper";
import AppButton from "@/components/AppButton";

import { ReactComponent as EnFlagIcon } from "@/assets/svgs/icons/en-flag.svg";
import { ReactComponent as HkFlagIcon } from "@/assets/svgs/icons/hk-flag.svg";
import { ReactComponent as CnFlagIcon } from "@/assets/svgs/icons/cn-flag.svg";
import { ReactComponent as ArrowDropdownIcon } from "@/assets/svgs/icons/arrow-dropdown.svg";

import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";

import useStyles from "./LanguageDropdown.styles";

const langToFlagIconComponentMap = {
  "en-US": EnFlagIcon,
  "zh-HK": HkFlagIcon,
  "zh-CN": CnFlagIcon,
};

const LanguageDropdown = () => {
  const [open, setOpen] = useState(false);

  const languageButtonRef = useRef<HTMLButtonElement>(null!);

  const router = useRouter();

  const { t, i18n } = useTranslation();

  const { classes } = useStyles();

  const handleToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    setOpen(false);
  };

  const handleLanguageChange = (locale: string) => () => {
    router.push(router.asPath, router.asPath, { locale: locale });
    window.NextPublic.lang = locale as any;
    setOpen(false);
  };

  return (
    <>
      <AppButton
        ref={languageButtonRef}
        color="black"
        edge="start"
        onClick={handleToggle}
        className={classes.languageButton}
      >
        <AppSvgIcon
          className={classes.languageButtonFlagIcon}
          component={
            langToFlagIconComponentMap[
              i18n.language as keyof typeof langToFlagIconComponentMap
            ]
          }
        />
        <Box
          component={"span"}
          sx={(theme) => ({
            display: "flex",
            alignItems: "center",
            transform: `rotate(${open ? "180deg" : "0deg"})`,
            transition: theme.transitions.create(["transform"]),
          })}
        >
          <AppSvgIcon
            className={classes.languageButtonArrowDropdownIcon}
            component={ArrowDropdownIcon}
          />
        </Box>
      </AppButton>

      <Popper
        className={classes.popper}
        open={open}
        anchorEl={languageButtonRef.current}
        role={undefined}
        placement="bottom-end"
        transition
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [0, 4],
            },
          },
        ]}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: "100% 0 0" }}>
            <AppPaper className={classes.popperMenu}>
              <ClickAwayListener onClickAway={handleClose}>
                <div className={classes.popperMenuContainer}>
                  <div className={classes.popperMenuLanguageList}>
                    {[
                      {
                        name: "繁體中文",
                        value: "zh-HK",
                      },
                      {
                        name: "English",
                        value: "en-US",
                      },
                    ].map((language, languageIndex) => (
                      <ButtonBase
                        key={languageIndex}
                        className={classes.popperMenuLanguageItem}
                        component={Typography}
                        variant="nunitoSemiBold16"
                        onClick={handleLanguageChange(language.value)}
                      >
                        {language.name}
                      </ButtonBase>
                    ))}
                  </div>
                </div>
              </ClickAwayListener>
            </AppPaper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default LanguageDropdown;
