declare module "react" {
  function forwardRef<T, P = {}>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

import { forwardRef } from "react";

import { commonHelpers } from "@/utils/helpers";

import { ButtonBase } from "@mui/material";

import useStyles from "./AppIconButton.styles";

import type { ButtonBaseProps } from "@mui/material";

type IconButtonColor = "default" | "primary";
type IconButtonEdge = "start" | "end" | "top" | "bottom" | "x" | "y" | "xy";

type CustomAppIconButtonProps<C extends string = IconButtonColor> = {
  size?: "medium" | "small" | "smaller";
  variant?: "text" | "contained" | "outlined" | "containedTonal";
  color?: C;
  edge?: IconButtonEdge | IconButtonEdge[];
};

type AppIconButtonProps<
  D extends React.ElementType = "button",
  P = {},
  C extends string = IconButtonColor
> = CustomAppIconButtonProps<C> &
  Omit<ButtonBaseProps<D, P>, keyof CustomAppIconButtonProps<C>> & {
    component?: D;
  };

const AppIconButton = <
  D extends React.ElementType = "button",
  P = {},
  C extends string = IconButtonColor
>(
  props: AppIconButtonProps<D, P, C>,
  ref: React.ForwardedRef<any>
) => {
  const {
    classes: muiClasses,
    size = "medium",
    variant = "text",
    color = "default",
    edge,
    ...rest
  } = props;

  const edges = Array.isArray(edge)
    ? edge
    : !commonHelpers.isEmpty(edge)
    ? [edge!]
    : [];

  const { classes, cx } = useStyles({ color });

  return (
    <ButtonBase
      ref={ref}
      classes={{
        ...muiClasses,
        root: cx(classes.root, muiClasses?.root, {
          [classes.sizeMedium]: size === "medium",
          [classes.sizeSmall]: size === "small",
          [classes.sizeSmaller]: size === "smaller",
          [classes.variantContained]: variant === "contained",
          [classes.variantOutlined]: variant === "outlined",
          [classes.variantContainedTonal]: variant === "containedTonal",
          [classes.edgeStart]: edge === "start",
          [classes.edgeEnd]: edge === "end",
          [classes.edgeStart]: edges.includes("start"),
          [classes.edgeEnd]: edges.includes("end"),
          [classes.edgeTop]: edges.includes("top"),
          [classes.edgeBottom]: edges.includes("bottom"),
          [classes.edgeX]: edges.includes("x"),
          [classes.edgeY]: edges.includes("y"),
          [classes.edgeXY]: edges.includes("xy"),
        }),
        disabled: cx(classes.disabled, muiClasses?.disabled),
      }}
      {...rest}
    />
  );
};

const AppIconButtonWithRef = forwardRef(AppIconButton);

export default AppIconButtonWithRef;
