import _round from "lodash/round";
import { commonConfig } from "@/utils/config";

import useStyles from "./LogoImage.styles";
import Image from "next/image";
import { useMemo } from "react";
import { Box, Link } from "@mui/material";
import AppLink from "@/components/AppLink";

export type LogoImageProps = {
  size?: number | string;
  href?: string;
} & Omit<React.HTMLAttributes<HTMLImageElement>, "src">;

const LogoImage = (props: LogoImageProps) => {
  const { className, size, href, ...rest } = props;

  const { classes, cx } = useStyles();

  return (
    <Box
      className={cx(classes.root, className)}
      component={href ? AppLink : "div"}
      href={href}
      {...rest}
    >
      <Image
        width={500}
        height={500}
        className={classes.logoImage}
        src={"/images/primary-logo.png"}
        alt={`${commonConfig.DOCUMENT_TITLE} logo`}
      />
    </Box>
  );
};

export default LogoImage;
