import { alpha, darken, rgbToHex } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles({
  name: "languageDropdown",
})((theme) => {
  return {
    languageButton: {
      padding: theme.spacing(0, 2),
      maxHeight: 50,
    },
    languageButtonFlagIcon: {
      fontSize: 36,
      marginRight: theme.spacing(1),
    },
    languageButtonArrowDropdownIcon: {
      fontSize: 14,
      color: theme.palette.common.black,
    },

    popper: {
      zIndex: theme.zIndex.tooltip,
    },
    popperMenu: {
      boxShadow: theme.app.shadows[0],
      minWidth: 266,
    },
    popperMenuContainer: {
      padding: theme.spacing(3.75, 3.75),
    },

    popperMenuLanguageList: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2.5),
    },
    popperMenuLanguageItem: {
      justifyContent: "flex-start",
      margin: `-10px ${theme.spacing(-2)}`,
      padding: `10px ${theme.spacing(2)}`,
      borderRadius: theme.shape.borderRadius,
      "&:hover": {
        backgroundColor: alpha(
          theme.palette.common.black,
          theme.palette.action.hoverOpacity
        ),
      },
    },
  };
});

export default useStyles;
