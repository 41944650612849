declare module "react" {
  function forwardRef<T, P = {}>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

import { forwardRef } from "react";

import { BadgeProps, Badge } from "@mui/material";

import useStyles from "./AppBadge.styles";

export type AppBadgeProps<
  D extends React.ElementType = "span",
  P = {}
> = BadgeProps<D, P>;

const AppBadge = <D extends React.ElementType = "span", P = {}>(
  props: AppBadgeProps<D, P>,
  ref: React.ForwardedRef<any>
) => {
  const { classes: muiClasses, ...rest } = props;

  const { classes, cx } = useStyles();

  return (
    <Badge
      ref={ref}
      classes={{
        ...muiClasses,
        badge: cx(classes.badge, muiClasses?.badge),
        anchorOriginTopRight: cx(
          classes.anchorOriginTopRight,
          muiClasses?.anchorOriginTopRight
        ),
      }}
      {...rest}
    />
  );
};

const AppBadgeWithRef = forwardRef(AppBadge);

export default AppBadgeWithRef;
