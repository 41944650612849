declare module "react" {
  function forwardRef<T, P = {}>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

import { ForwardedRef, forwardRef } from "react";

import { Box, CircularProgress, Typography } from "@mui/material";

import { useTranslation } from "next-i18next";

import useStyles from "./LoadingOverlay.styles";

import type { BoxProps } from "@mui/material";

type LoadingOverlayProps = React.HTMLAttributes<HTMLDivElement> & {
  classes?: {
    root?: string;
    loading?: string;
  };
  loading?: boolean;
  loadingText?: string;
  unmounted?: boolean;
} & Pick<BoxProps, "sx"> &
  React.ComponentPropsWithoutRef<"div">;

const LoadingOverlay = (props: LoadingOverlayProps, ref: ForwardedRef<any>) => {
  const {
    loading = false,
    loadingText,
    unmounted,
    children,
    className,
    classes: appClasses,
    ...rest
  } = props;

  const { classes, cx } = useStyles();

  const { t } = useTranslation();

  return (
    <Box
      ref={ref}
      className={cx(
        classes.root,
        appClasses?.root,
        {
          [classes.loading]: loading,
          [appClasses?.loading || ""]: loading && !!appClasses?.loading,
        },
        !!className && className
      )}
      {...rest}
    >
      {loading && (
        <div className={classes.loadingIconWrapper}>
          <CircularProgress />
          {!!loadingText && (
            <Typography variant="nunitoBold16" color="inherit">
              {loadingText ?? `${t("Loading")}...`}
            </Typography>
          )}
        </div>
      )}
      {(!unmounted || (!loading && unmounted)) && children}
    </Box>
  );
};

const LoadingOverlayWithRef = forwardRef(LoadingOverlay);

export default LoadingOverlayWithRef;
