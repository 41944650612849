import { makeStyles } from "tss-react/mui";
import _get from "lodash/get";

const iconColorToKeyMap = {
  default: "text.primary",
  primary: "primary.main",
} as { [key: string]: string };

const useStyles = makeStyles<{ color: string }>({
  name: "appSvgIcon",
})((theme, params) => {
  const color =
    _get(theme.palette, iconColorToKeyMap[params.color]) ||
    _get(theme.palette, params.color!) ||
    params.color;

  return {
    root: {
      fill: "currentColor",
      transition: `fill ${theme.transitions.duration.shorter}ms ${theme.transitions.easing.easeInOut} 0ms`,
    },
    strokeColor: {
      fill: "none",
      stroke: "currentcolor",
      transition: `stroke ${theme.transitions.duration.shorter}ms ${theme.transitions.easing.easeInOut} 0ms`,
    },
    color: {
      color,
    },
  };
});

export default useStyles;
