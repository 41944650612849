import _kebabCase from "lodash/kebabCase";

import { commonHelpers } from "@/utils/helpers";

import { ButtonBase, Grid, Tooltip, Typography, Box } from "@mui/material";
import { darken } from "@mui/material/styles";
import PerfectScrollbar from "react-perfect-scrollbar";
import AppLink from "@/components/AppLink";
import LoadingOverlay from "@/components/LoadingOverlay";
import AppImageV2 from "@/components/AppImageV2";

import { ReactComponent as ClassTypePlaygroupProgramSvg } from "@/assets/svgs/class-type-playgroup-program.svg";
import { ReactComponent as ClassTypeKindergartenPreparatoryProgramSvg } from "@/assets/svgs/class-type-kindergarten-preparatory-program.svg";
import { ReactComponent as ClassTypeNurseryStoryProgramSvg } from "@/assets/svgs/class-type-nursery-story-program.svg";
import { ReactComponent as ClassTypeKidsStorytellingAndLogicalReasoningProgramSvg } from "@/assets/svgs/class-type-kids-storytelling-and-logical-reasoning-program.svg";
import { ReactComponent as ClassTypeWordSmartChineseVocabularyCourseSvg } from "@/assets/svgs/class-type-word-smart-chinese-vocabulary-course.svg";

import { useAppSelector } from "@/hooks";

import useStyles from "./ProgramAndCourseTooltip.styles";

type ProgramAndCourseTooltipProps = {
  open?: boolean;
  children: React.ReactElement<any, any>;
  onClose?: () => void;
};

const programIconComponents = [
  ClassTypePlaygroupProgramSvg,
  ClassTypeKindergartenPreparatoryProgramSvg,
  ClassTypeNurseryStoryProgramSvg,
  ClassTypeKidsStorytellingAndLogicalReasoningProgramSvg,
  ClassTypeWordSmartChineseVocabularyCourseSvg,
];

const ProgramAndCourseTooltip = (props: ProgramAndCourseTooltipProps) => {
  const { children, open, onClose } = props;

  const $s_discoveryPrograms = useAppSelector(
    (state) => state.program.discoveryPrograms
  );
  const $s_discoveryProgramsLoading = useAppSelector(
    (state) => state.program.discoveryProgramsLoading
  );

  const { classes } = useStyles();

  return (
    <Tooltip
      open={open}
      onClose={onClose}
      classes={{
        tooltip: classes.tooltip,
      }}
      placement="bottom"
      disableHoverListener={commonHelpers.isMobile()}
      disableFocusListener={commonHelpers.isMobile()}
      disableTouchListener={commonHelpers.isMobile()}
      title={
        <Box
          component={
            commonHelpers.isIOS() || commonHelpers.isMobile()
              ? "div"
              : PerfectScrollbar
          }
          className={classes.section}
        >
          <LoadingOverlay loading={$s_discoveryProgramsLoading} unmounted>
            <Grid container spacing={3.75}>
              {$s_discoveryPrograms.map(
                (discoveryProgram, discoveryProgramIndex) => (
                  <Grid key={discoveryProgramIndex} item xs={12} md={12 / 5}>
                    <ButtonBase
                      className={classes.programCard}
                      sx={(theme) => ({
                        backgroundColor:
                          discoveryProgram.background_color ??
                          theme.palette.common.lightPink,
                        color:
                          discoveryProgram.title_color ??
                          theme.palette.common.white,
                        "&:hover": {
                          backgroundColor: darken(
                            discoveryProgram.background_color ??
                              theme.palette.common.lightPink,
                            theme.palette.contrastThreshold / 30
                          ),
                        },
                      })}
                      LinkComponent={AppLink}
                      href={`/programs/${_kebabCase(discoveryProgram.name)}-${
                        discoveryProgram.id
                      }`}
                      onClick={onClose}
                    >
                      {/* <AppSvgIcon
                        className={classes.programCardIcon}
                        component={
                          programIconComponents[(discoveryProgram?.id || 0) % 5]
                        }
                        sx={{ "--font-size": "100px" }}
                      /> */}
                      <AppImageV2
                        className={classes.programCardIconImage}
                        src={discoveryProgram?.images?.icon}
                        alt={discoveryProgram.name}
                        width={500}
                        height={500}
                      />

                      <Typography
                        className={classes.programCardTitle}
                        color="inherit"
                        variant="nunitoBold16"
                        align="center"
                        textTransform="capitalize"
                      >
                        {discoveryProgram.name}
                      </Typography>
                    </ButtonBase>
                  </Grid>
                )
              )}
            </Grid>
          </LoadingOverlay>
        </Box>
      }
    >
      {children}
    </Tooltip>
  );
};

export default ProgramAndCourseTooltip;
