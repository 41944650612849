import { useState, forwardRef } from "react";

import { commonHelpers } from "@/utils/helpers";
import { authConstants } from "@/utils/constants";

import { ButtonBase, Typography } from "@mui/material";
import ProgramAndCourseTooltip from "./components/ProgramAndCourseTooltip";
import AppSvgIcon from "@/components/AppSvgIcon";
import AppLink from "@/components/AppLink";

import { ReactComponent as ArrowDropdown } from "@/assets/svgs/icons/arrow-dropdown.svg";

import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";

import useStyles from "./MenuTabs.styles";
import { useAppSelector } from "@/hooks";

const ButtonLink = forwardRef<any>((props, ref) => (
  <ButtonBase ref={ref} LinkComponent={AppLink} {...props} />
));

const MenuTabs = () => {
  const [programAndCourseTooltipOpen, setProgramAndCourseTooltipOpen] =
    useState(false);

  const { pathname } = useRouter();
  const { classes, cx } = useStyles();

  const { t } = useTranslation();

  const menus = [
    {
      label: "Program & Course",
      path: "",
      hasProgramAndCourseTooltip: true,
      activePath: new RegExp("^/programs"),
    },
    {
      label: "About us",
      path: "/about-us",
    },
    {
      label: "Market elite",
      path: "/our-professionals",
      activePath: new RegExp("^/our-professionals"),
    },
    // {
    //   label: "Timetable",
    //   path: "/timetable",
    //   permissionRoleId: authConstants.ROLE_PARENT_ID,
    // },
    {
      label: "Latest news",
      path: "/latest-news",
      activePath: new RegExp("^/latest-news"),
    },
    {
      label: "Contact us",
      path: "/contact-us",
    },
  ];

  const $s_authUser = useAppSelector((state) => state.auth.user);

  const handleTooltipOpen = () => {
    setProgramAndCourseTooltipOpen(true);
  };

  const handleTooltipToggle = () => {
    setProgramAndCourseTooltipOpen((open) => !open);
  };

  const handleTooltipClose = () => {
    setProgramAndCourseTooltipOpen(false);
  };

  return (
    <>
      <ProgramAndCourseTooltip
        open={programAndCourseTooltipOpen}
        onClose={handleTooltipClose}
      >
        <div className={classes.root}>
          <div className={classes.menuTabList}>
            {menus.map((menu, menuIndex) => {
              if (
                menu.permissionRoleId &&
                !!$s_authUser?.id &&
                menu.permissionRoleId !== $s_authUser?.user_role_id
              )
                return;
              return (
                <Typography
                  key={menuIndex}
                  className={cx(classes.menuTabItem, {
                    [classes.menuTabItemSelected]:
                      (menu.activePath &&
                        !!pathname.match(menu.activePath || "")) ||
                      menu.path === pathname,
                  })}
                  variant="nunitoExtraBoldAA16"
                  onMouseOver={() => {
                    if (menu.hasProgramAndCourseTooltip) {
                      handleTooltipOpen();
                    } else {
                      handleTooltipClose();
                    }
                  }}
                  onClick={() => {
                    if (
                      !commonHelpers.isMobile() ||
                      !menu.hasProgramAndCourseTooltip
                    )
                      return;
                    handleTooltipToggle();
                  }}
                  component={menu.path ? ButtonLink : "a"}
                  href={menu.path || undefined}
                >
                  <Typography variant="inherit" noWrap color="inherit">
                    {t(menu.label)}
                  </Typography>
                  {menu.hasProgramAndCourseTooltip && (
                    <AppSvgIcon
                      component={ArrowDropdown}
                      className={classes.menuTabItemArrowDropdownIcon}
                      sx={{
                        transform: `rotate(${
                          programAndCourseTooltipOpen ? "180deg" : "0deg"
                        })`,
                      }}
                    />
                  )}
                </Typography>
              );
            })}
          </div>
        </div>
      </ProgramAndCourseTooltip>
    </>
  );
};

export default MenuTabs;
