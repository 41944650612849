declare module "react" {
  function forwardRef<T, P = {}>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

import { forwardRef } from "react";

import { Toolbar, ToolbarProps } from "@mui/material";

import useStyles from "./MenuTabsToolbar.styles";

const MenuTabsToolbar = (props: ToolbarProps, ref: React.ForwardedRef<any>) => {
  const { classes: muiClasses, ...rest } = props;

  const { classes, cx } = useStyles();

  return (
    <Toolbar
      classes={{
        ...muiClasses,
        root: cx(classes.root, muiClasses?.root),
      }}
      ref={ref}
      disableGutters
      {...rest}
    />
  );
};

const MenuTabsToolbarWithRef = forwardRef(MenuTabsToolbar);

export default MenuTabsToolbarWithRef;
