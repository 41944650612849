import { alpha, darken, rgbToHex } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles({
  name: "mainHeader",
})((theme) => {
  return {
    root: {
      backgroundColor: theme.palette.common.white,
    },
    menuToolbar: {
      width: `calc(100% + ${theme.spacing(3 * 2)})`,
      marginLeft: theme.spacing(-3),
      marginRight: theme.spacing(-3),
    },
    logoImage: {
      height: 148,
      width: "auto",
      [theme.breakpoints.down("md")]: {
        height: "auto",
        maxHeight: 90,
        maxWidth: "calc(100vw - 240px)",
      },
    },

    cartButton: {
      backgroundColor: alpha(theme.palette.common.orange, 0.15),
      "&:hover": {
        backgroundColor: darken(
          rgbToHex(alpha(theme.palette.common.orange, 0.15)),
          0.3
        ),
      },
    },
    cartButtonIcon: {
      color: theme.palette.common.orange,
    },
  };
});

export default useStyles;
