import { alpha, darken, rgbToHex } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import _get from "lodash/get";

const colorToKeyMap = {
  default: "text.primary",
  primary: "primary.main",
} as { [key: string]: string };

const useStyles = makeStyles<
  { color: string },
  | "edgeStart"
  | "edgeEnd"
  | "edgeTop"
  | "edgeBottom"
  | "edgeX"
  | "edgeY"
  | "edgeXY"
  | "disabled"
>({
  name: "appIconButton",
  uniqId: "EJeTBC",
})((theme, params, classes) => {
  const color =
    _get(theme.palette, colorToKeyMap[params.color]) ||
    _get(theme.palette, params.color!) ||
    params.color;

  let variantContainedIconColor = theme.palette.common.white;
  let variantOutlinedIconColor = color;
  try {
    variantContainedIconColor = theme.palette.getContrastText(color);
  } catch (error) {}

  const sizeMediumPadding = 11;
  const sizeSmallPadding = 8;
  const sizeSmallerPadding = 6;

  return {
    root: {
      borderRadius: theme.shape.borderRadius,
      color: color,
    },
    sizeMedium: {
      fontSize: theme.typography.pxToRem(28),
      minWidth: 50,
      minHeight: 50,
      padding: sizeMediumPadding,
    },
    sizeSmall: {
      fontSize: theme.typography.pxToRem(24),
      minWidth: 40,
      minHeight: 40,
      borderRadius: theme.shape.borderRadius / 2,
      padding: sizeSmallPadding,
      [`&.${classes.edgeStart}`]: {
        marginLeft: -sizeSmallPadding,
      },
      [`&.${classes.edgeEnd}`]: {
        marginRight: -sizeSmallPadding,
      },
      [`&.${classes.edgeTop}`]: {
        marginTop: -sizeSmallPadding,
      },
      [`&.${classes.edgeBottom}`]: {
        marginBottom: -sizeSmallPadding,
      },
      [`&.${classes.edgeX}`]: {
        marginLeft: -sizeSmallPadding,
        marginRight: -sizeSmallPadding,
      },
      [`&.${classes.edgeY}`]: {
        marginTop: -sizeSmallPadding,
        marginBottom: -sizeSmallPadding,
      },
      [`&.${classes.edgeXY}`]: {
        marginLeft: -sizeSmallPadding,
        marginRight: -sizeSmallPadding,
        marginTop: -sizeSmallPadding,
        marginBottom: -sizeSmallPadding,
      },
    },
    sizeSmaller: {
      fontSize: theme.typography.pxToRem(16),
      minWidth: 30,
      minHeight: 30,
      borderRadius: theme.shape.borderRadius / 2,
      padding: sizeSmallerPadding,
      [`&.${classes.edgeStart}`]: {
        marginLeft: -sizeSmallerPadding,
      },
      [`&.${classes.edgeEnd}`]: {
        marginRight: -sizeSmallerPadding,
      },
      [`&.${classes.edgeTop}`]: {
        marginTop: -sizeSmallerPadding,
      },
      [`&.${classes.edgeBottom}`]: {
        marginBottom: -sizeSmallerPadding,
      },
      [`&.${classes.edgeX}`]: {
        marginLeft: -sizeSmallerPadding,
        marginRight: -sizeSmallerPadding,
      },
      [`&.${classes.edgeY}`]: {
        marginTop: -sizeSmallerPadding,
        marginBottom: -sizeSmallerPadding,
      },
      [`&.${classes.edgeXY}`]: {
        marginLeft: -sizeSmallerPadding,
        marginRight: -sizeSmallerPadding,
        marginTop: -sizeSmallerPadding,
        marginBottom: -sizeSmallerPadding,
      },
    },
    edgeStart: {
      marginLeft: -sizeMediumPadding,
    },
    edgeEnd: {
      marginRight: -sizeMediumPadding,
    },
    edgeTop: {
      marginTop: -sizeMediumPadding,
    },
    edgeBottom: {
      marginBottom: -sizeMediumPadding,
    },
    edgeX: {
      marginLeft: -sizeMediumPadding,
      marginRight: -sizeMediumPadding,
    },
    edgeY: {
      marginTop: -sizeMediumPadding,
      marginBottom: -sizeMediumPadding,
    },
    edgeXY: {
      marginLeft: -sizeSmallPadding,
      marginRight: -sizeSmallPadding,
      marginTop: -sizeMediumPadding,
      marginBottom: -sizeMediumPadding,
    },
    variantContained: {
      backgroundColor: color,
      color: variantContainedIconColor,
      "&:hover": {
        backgroundColor: darken(color, theme.palette.contrastThreshold / 10),
      },
      [`&.${classes.disabled}`]: {
        backgroundColor: theme.palette.common.grey,
        color: theme.palette.common.white,
      },
    },
    variantOutlined: {
      border: `1px solid ${color}`,
      color: variantOutlinedIconColor,
      "&:hover": {
        backgroundColor: alpha(color, theme.palette.action.hoverOpacity),
      },
    },
    variantContainedTonal: {
      backgroundColor: alpha(color, 0.15),
      color,
      "&:hover": {
        backgroundColor: darken(
          rgbToHex(alpha(color, 0.15)),
          theme.palette.contrastThreshold / 10
        ),
      },
      [`&.${classes.disabled}`]: {
        backgroundColor: darken(
          rgbToHex(alpha(theme.palette.common.grey, 0.15)),
          theme.palette.contrastThreshold / 10
        ),
      },
    },
    disabled: {},
  };
});

export default useStyles;
