import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles({
  name: "menuTabs",
})((theme) => {
  return {
    root: {
      width: "100%",
      height: "100%",
      [theme.breakpoints.down("md")]: {
        width: "auto",
        overflowX: "auto",
      },
    },
    menuTabList: {
      height: "100%",
      overflow: "hidden",
      display: "flex",
      justifyContent: "space-between",
    },
    menuTabItem: {
      overflow: "hidden",
      height: "100%",
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      display: "flex",
      alignItems: "center",
      "&:hover": {
        color: theme.palette.common.orange,
      },
    },
    menuTabItemSelected: {
      color: theme.palette.common.orange,
    },

    menuTabTooltip: {
      marginTop: "2px !important",
      width: theme.breakpoints.values.lg,
      maxWidth: `calc(100vw - ${theme.spacing(3 * 2)})`,
    },
    menuTabItemArrowDropdownIcon: {
      fontSize: 14,
      marginLeft: theme.spacing(1),
      transition: theme.transitions.create(["transform"]),
    },
  };
});

export default useStyles;
