import { AppBar, Box } from "@mui/material";

import { authConstants } from "@/utils/constants";

import AppContainer from "@/components/AppContainer";
import AppToolbar from "./components/AppToolbar";
import AppLink from "@/components/AppLink";
import AppButton from "@/components/AppButton";
import LogoImage from "@/components/LogoImage";
import AppBadge from "@/components/AppBadge";
import AppIconButton from "@/components/AppIconButton";
import AppSvgIcon from "@/components/AppSvgIcon";

import NotificationDropdown from "./components/NotificationDropdown";
import UserDropdown from "./components/UserDropdown";
import LanguageDropdown from "./components/LanguageDropdown";
import MenuTabsToolbar from "./components/MenuTabsToolbar";
import MenuTabs from "./components/MenuTabs";

import { ReactComponent as CartIcon } from "@@/public/images/svgs/icons/cart.svg";

import { useAppSelector } from "@/hooks";
import { useTranslation } from "next-i18next";

import useStyles from "./MainHeader.styles";

const CartButton = () => {
  const { classes } = useStyles();

  const $s_draftClassRegistrationOrderTotalQuantity = useAppSelector(
    (state) =>
      state.classRegistrationOrder.draftClassRegistrationOrder.total_quantity
  );
  const $s_authUser = useAppSelector((state) => state.auth.user);

  return $s_authUser?.user_role_id === authConstants.ROLE_PARENT_ID ? (
    <AppBadge
      badgeContent={$s_draftClassRegistrationOrderTotalQuantity}
      color="primary"
    >
      <AppIconButton
        className={classes.cartButton}
        component={AppLink}
        href="/cart-and-checkout"
      >
        <AppSvgIcon
          className={classes.cartButtonIcon}
          fontSize="inherit"
          component={CartIcon}
        />
      </AppIconButton>
    </AppBadge>
  ) : null;
};

const MainHeader = () => {
  const { classes } = useStyles();

  const $s_hasAuth = useAppSelector((state) => !!state.auth.user?.id);

  const { t } = useTranslation();

  return (
    <header>
      <AppBar className={classes.root} elevation={0}>
        <AppContainer>
          <AppToolbar>
            <LogoImage className={classes.logoImage} href="/" />
            <Box flexGrow={1} />
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              gap={3}
              marginRight={5.75}
            >
              {!$s_hasAuth && (
                <AppButton
                  variant="contained"
                  elevation
                  LinkComponent={AppLink}
                  href={`/sign-in?redirect_path=${window?.location?.pathname}`}
                >
                  {t("Login")}
                </AppButton>
              )}
              {$s_hasAuth && (
                <>
                  <CartButton />
                  <NotificationDropdown />
                  <UserDropdown />
                </>
              )}
            </Box>
            <LanguageDropdown />
          </AppToolbar>
        </AppContainer>
        <AppContainer>
          <MenuTabsToolbar className={classes.menuToolbar}>
            <MenuTabs />
          </MenuTabsToolbar>
        </AppContainer>
      </AppBar>
      <div>
        <AppToolbar />
        <MenuTabsToolbar />
      </div>
    </header>
  );
};

export default MainHeader;
