import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles<void, "programCardIcon">({
  name: "programAndCourseTooltip",
  uniqId: "nFDqUM",
})((theme, _, classes) => {
  return {
    tooltip: {
      margin: 0,
      marginTop: "2px !important",
      width: `calc(${theme.breakpoints.values.lg}px - ${theme.spacing(3 * 2)})`,
      maxWidth: `calc(100vw - ${theme.spacing(3 * 2)})`,
      backgroundColor: theme.palette.common.white,
      boxShadow: theme.app.shadows[0],
      color: theme.palette.text.primary,
      padding: 0,
    },
    section: {
      // padding: theme.spacing(5.25, 7.75),
      padding: theme.spacing(7.25),
      maxHeight: "calc(100vh - 90px - 50px - 16px - 2px)",
      overflow: "auto",
      [theme.breakpoints.down("md")]: {
        padding: theme.spacing(3.75),
      },
    },
    programCard: {
      width: "100%",
      padding: "40px 20px",
      height: "100%",
      borderRadius: theme.shape.borderRadius,
      position: "relative",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "flex-start",
      gap: theme.spacing(2.75),
      "&:hover": {
        [`& .${classes.programCardIcon}`]: {
          fontSize: "calc(var(--font-size) + var(--font-size)/2)",
        },
      },
    },
    programCardIcon: {
      position: "absolute",
      top: "calc(100%/3)",
      transform: "translateY(-50%)",
      transition: theme.transitions.create(["font-size"]),
      fontSize: "var(--font-size)",
    },
    programCardTitle: {},
    programCardIconImage: {
      height: 100,
      width: "auto",
      maxWidth: "100%",
    },
    dividerVertical: {
      width: 2,
      backgroundColor: theme.palette.common.bg,
    },
  };
});

export default useStyles;
