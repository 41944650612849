import { useEffect, useMemo } from "react";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";

import { storeGeneralInfoAction, storeProgramAction } from "@/store";

import MainHeader from "@/components/MainHeader";
import MainFooter from "@/components/MainFooter";

import { useAppDispatch, useAppSelector, useReduxQueryCache } from "@/hooks";

import useStyles from "./Main.styles";

type MainProps = {
  children: React.ReactNode;
};

const FetchData = () => {
  const dispatch = useAppDispatch();

  const $s_programAction = useMemo(
    () => bindActionCreators(storeProgramAction, dispatch),
    [dispatch]
  );
  const $s_discoveryPrograms = useAppSelector(
    (state) => state.program.discoveryPrograms
  );
  const $s_discoveryProgramsLoading = useAppSelector(
    (state) => state.program.discoveryProgramsLoading
  );
  const $s_discoveryProgramsError = useAppSelector(
    (state) => state.program.discoveryProgramsError
  );

  const $s_generalInfoAction = useMemo(
    () => bindActionCreators(storeGeneralInfoAction, dispatch),
    [dispatch]
  );
  const $s_contacts = useAppSelector((state) => state.generalInfo.contacts);
  const $s_contactsLoading = useAppSelector(
    (state) => state.generalInfo.contactsLoading
  );
  const $s_contactsError = useAppSelector(
    (state) => state.generalInfo.contactsError
  );

  useReduxQueryCache(
    "discoveryPrograms",
    () => $s_programAction.fetchDiscoveryProgramsSaga(),
    {
      cacheTime: "infinity",
      data: $s_discoveryPrograms,
      loading: $s_discoveryProgramsLoading,
    }
  );
  useReduxQueryCache(
    "contacts",
    () => $s_generalInfoAction.fetchContactsSaga(),
    {
      cacheTime: "infinity",
      data: $s_contacts,
      loading: $s_contactsLoading,
    }
  );
  useEffect(() => {
    if (!$s_discoveryProgramsLoading && !!$s_discoveryProgramsError) {
      toast.error($s_discoveryProgramsError);
    }
  }, [$s_discoveryProgramsLoading, $s_discoveryProgramsError]);

  useEffect(() => {
    if (!$s_contactsLoading && !!$s_contactsError) {
      toast.error($s_contactsError);
    }
  }, [$s_contactsLoading, $s_contactsError]);

  return null;
};

const Main = (props: MainProps) => {
  const { children } = props;

  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <FetchData />
      <MainHeader />
      <main className={classes.main}>{children}</main>
      <MainFooter />
    </div>
  );
};

export default Main;
