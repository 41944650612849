import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles({
  name: "menuTabsToolbar",
})((theme) => {
  return {
    root: {
      position: "relative",
      minHeight: 55,
      [theme.breakpoints.up("xs")]: {
        height: 55,
      },
      [theme.breakpoints.up("sm")]: {
        height: 55,
      },
      [theme.breakpoints.up("md")]: {
        height: 55,
      },
    },
  };
});

export default useStyles;
