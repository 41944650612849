import { useState, useRef, useEffect, useMemo, Fragment } from "react";
import { bindActionCreators } from "redux";
import _kebabCase from "lodash/kebabCase";

import { storeAuthAction } from "@/store";
import { authConstants } from "@/utils/constants";

import {
  Avatar,
  Box,
  ClickAwayListener,
  Collapse,
  Divider,
  Grow,
  Popper,
  Typography,
} from "@mui/material";
import AppSvgIcon from "@/components/AppSvgIcon";
import AppPaper from "@/components/AppPaper";
import AppLink from "@/components/AppLink";
import AppIconButton from "@/components/AppIconButton";

import { ReactComponent as PersonIcon } from "@/assets/svgs/icons/person.svg";
import { ReactComponent as ArrowDropdownIcon } from "@/assets/svgs/icons/arrow-dropdown.svg";

import { useAppDispatch, useAppSelector } from "@/hooks";
import { useTranslation } from "next-i18next";

import useStyles from "./UserDropdown.styles";

const userNavigationMenus = [
  {
    label: "Class management",
    path: "/user/staff/classes",
    roleId: authConstants.ROLE_STAFF_ID,
  },
  {
    label: "Student management",
    path: "/user/staff/kids",
    roleId: authConstants.ROLE_STAFF_ID,
  },
  {
    label: "Register on behalf of parent",
    path: "/user/staff/register-on-behalf-of-parent",
    roleId: authConstants.ROLE_STAFF_ID,
  },
  {
    label: "Manage kid profiles",
    path: "/user/parent/kids",
    enabledDivider: true,
    roleId: authConstants.ROLE_PARENT_ID,
  },
  {
    label: "Account",
    path: "/user/account/profile",
    roleId: authConstants.ROLE_PARENT_ID,
  },
  {
    label: "My class",
    roleId: authConstants.ROLE_PARENT_ID,
    childMenus: [
      {
        label: "Class list",
        path: "/user/parent/my-class/classes-kids",
      },
      {
        label: "Photo/Album",
        path: "/user/parent/my-class/albums",
      },
    ],
  },
  {
    label: "Change password",
    path: "/user/change-password",
    roleId: authConstants.ROLE_PARENT_ID,
  },
];

const UserMenu = (props: {
  onClose: (event: MouseEvent | TouchEvent) => void;
}) => {
  const { onClose } = props;

  const [collapsedMenu, setCollapsedMenu] = useState<{
    [key: number]: boolean;
  }>({});

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const $s_authAction = useMemo(
    () => bindActionCreators(storeAuthAction, dispatch),
    [dispatch]
  );

  const $s_authUser = useAppSelector((state) => state.auth.user);

  const { classes } = useStyles();

  const handleMenuToggle = (menuIndex: number) => () => {
    setCollapsedMenu((prevCollapsedMenu) => ({
      ...prevCollapsedMenu,
      [menuIndex]: !prevCollapsedMenu[menuIndex],
    }));
  };

  const handleLogout = () => {
    $s_authAction.signOut();
  };

  return (
    <div className={classes.popperMenuUserMenuList}>
      {userNavigationMenus.map(
        (userNavigationMenu, userNavigationMenuIndex) => (
          <Fragment key={userNavigationMenuIndex}>
            {userNavigationMenu.roleId === $s_authUser?.user_role_id && (
              <>
                <div>
                  <Typography
                    variant="nunitoSemiBold16"
                    className={classes.popperMenuUserMenuItem}
                    component={userNavigationMenu.path ? AppLink : "div"}
                    href={userNavigationMenu.path || undefined}
                    onClick={
                      !userNavigationMenu.path
                        ? handleMenuToggle(userNavigationMenuIndex)
                        : onClose
                    }
                  >
                    {t(userNavigationMenu.label)}
                    {(userNavigationMenu.childMenus?.length || 0) > 0 && (
                      <Box
                        component={"span"}
                        sx={(theme) => ({
                          display: "flex",
                          alignItems: "center",
                          transform: `rotate(${
                            !!collapsedMenu[userNavigationMenuIndex]
                              ? "180deg"
                              : "0deg"
                          })`,
                          transition: theme.transitions.create(["transform"]),
                        })}
                      >
                        <AppSvgIcon
                          className={
                            classes.popperMenuUserMenuArrowDropdownIcon
                          }
                          component={ArrowDropdownIcon}
                          sx={{
                            transform: `rotate(${
                              !!collapsedMenu[userNavigationMenuIndex]
                                ? "360deg"
                                : "0deg"
                            })`,
                          }}
                        />
                      </Box>
                    )}
                  </Typography>
                  {(userNavigationMenu.childMenus?.length || 0) > 0 && (
                    <Collapse in={!!collapsedMenu[userNavigationMenuIndex]}>
                      <Box
                        className={classes.popperMenuUserMenuList}
                        sx={{ marginTop: 2.5, paddingLeft: 2.5 }}
                      >
                        {userNavigationMenu.childMenus!.map(
                          (
                            userNavigationChildMenu,
                            userNavigationChildMenuIndex
                          ) => (
                            <Typography
                              key={userNavigationChildMenuIndex}
                              className={classes.popperMenuUserMenuItem}
                              component={
                                userNavigationChildMenu.path ? AppLink : "div"
                              }
                              href={userNavigationChildMenu.path || undefined}
                              onClick={onClose}
                            >
                              {t(userNavigationChildMenu.label)}
                            </Typography>
                          )
                        )}
                      </Box>
                    </Collapse>
                  )}
                </div>
                {!!userNavigationMenu.enabledDivider && <Divider />}
              </>
            )}
          </Fragment>
        )
      )}
      <Typography
        variant="nunitoSemiBold16"
        className={classes.popperMenuUserMenuItem}
        onClick={handleLogout}
      >
        {t("Logout")}
      </Typography>
    </div>
  );
};

const MenuAppPaper = (props: {
  onClose: (event: MouseEvent | TouchEvent) => void;
}) => {
  const { onClose } = props;

  const [appPaperRectY, setAppPaperRectY] = useState<number>();

  const appPaperRef = useRef<HTMLDivElement>(null!);

  const $s_authUser = useAppSelector((state) => state.auth.user);

  const { classes } = useStyles();

  const updateAppPaperStyle = () => {
    const appPaperBoundingClientRect =
      appPaperRef.current.getBoundingClientRect();
    setAppPaperRectY(appPaperBoundingClientRect.y);
  };

  useEffect(() => {
    const checkAppPaperBoundingClientRectTimeout = setTimeout(() =>
      updateAppPaperStyle()
    );
    return () => {
      clearTimeout(checkAppPaperBoundingClientRectTimeout);
    };
  }, []);

  return (
    <AppPaper
      ref={appPaperRef}
      className={classes.popperMenu}
      sx={{
        maxHeight: `calc(100vh - ${appPaperRectY}px - 6px)`,
      }}
    >
      <ClickAwayListener onClickAway={onClose}>
        <div className={classes.popperMenuContainer}>
          {($s_authUser?.kids || []).length > 0 && (
            <div className={classes.popperMenuPersonList}>
              {($s_authUser?.kids || []).map((kid, kidIndex) => (
                <AppLink
                  key={kidIndex}
                  className={classes.popperMenuPersonItem}
                  href={`/user/parent/kids/${_kebabCase(kid.name)}-${kid.id}`}
                >
                  <Avatar
                    src={kid.avatar || ""}
                    className={classes.popperMenuPersonAvatar}
                  >
                    <AppSvgIcon
                      color="inherit"
                      fontSize="inherit"
                      component={PersonIcon}
                    />
                  </Avatar>
                  <Typography className={classes.popperMenuPersonTitle}>
                    {kid.name}
                  </Typography>
                </AppLink>
              ))}
            </div>
          )}
          <UserMenu onClose={onClose} />
        </div>
      </ClickAwayListener>
    </AppPaper>
  );
};

const UserDropdown = () => {
  const [open, setOpen] = useState(false);

  const avatarButtonRef = useRef<HTMLButtonElement>(null!);

  const { classes } = useStyles();

  const handleToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    setOpen(false);
  };

  return (
    <>
      <Avatar
        ref={avatarButtonRef}
        className={classes.avatarButton}
        variant="rounded"
        component={AppIconButton}
        onClick={handleToggle}
      >
        <AppSvgIcon
          className={classes.avatarButtonIcon}
          component={PersonIcon}
        />
      </Avatar>
      <Popper
        className={classes.popper}
        open={open}
        anchorEl={avatarButtonRef.current}
        role={undefined}
        placement="bottom-end"
        transition
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [0, 6],
            },
          },
        ]}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: "100% 0 0" }}>
            <div>
              <MenuAppPaper onClose={handleClose} />
            </div>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default UserDropdown;
