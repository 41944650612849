import { darken, rgbToHex } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles<void, "popperMenuPersonTitle">({
  name: "userDropdown",
})((theme, _, classes) => {
  return {
    avatarButton: {
      backgroundColor: theme.palette.common.bg,
      "&:hover": {
        backgroundColor: darken(
          theme.palette.common.bg,
          theme.palette.action.hoverOpacity
        ),
      },
    },
    avatarButtonIcon: {
      fontSize: 28,
      color: theme.palette.common.black,
    },

    popper: {
      zIndex: theme.zIndex.tooltip,
    },
    popperMenu: {
      boxShadow: theme.app.shadows[0],
      minWidth: 266,
      overflow: "auto",
    },
    popperMenuContainer: {
      padding: theme.spacing(3.75, 4.25),
    },

    popperMenuPersonList: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2.5),
      marginBottom: theme.spacing(3.75),
    },
    popperMenuPersonItem: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(2),
      "&:hover": {
        [`& .${classes.popperMenuPersonTitle}`]: {
          color: theme.palette.common.blue,
        },
      },
    },
    popperMenuPersonAvatar: {
      width: 36,
      height: 36,
      fontSize: 20,
      backgroundColor: theme.palette.common.bg,
      color: theme.palette.common.black,
      borderRadius: 10,
      boxShadow: theme.app.shadows[1],
    },
    popperMenuPersonTitle: {},

    popperMenuUserMenuList: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2.5),
    },
    popperMenuUserMenuItem: {
      display: "flex",
      justifyContent: "space-between",
      cursor: "pointer",
      "&:hover": {
        color: theme.palette.common.blue,
      },
    },
    popperMenuUserMenuArrowDropdownIcon: {
      fontSize: 14,
    },
    popperMenuUserMenuChildList: {},
    popperMenuUserMenuChildItem: {},
  };
});

export default useStyles;
