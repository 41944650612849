import { authConstants } from "@/utils/constants";

import AppIconButton from "@/components/AppIconButton";
import AppSvgIcon from "@/components/AppSvgIcon";
import AppBadge from "@/components/AppBadge";
import AppLink from "@/components/AppLink";

import { useAppSelector } from "@/hooks";

import { ReactComponent as NotificationIcon } from "@/assets/svgs/icons/notifications.svg";

import useStyles from "./NotificationDropdown.styles";

const NotificationDropdown = () => {
  const { classes } = useStyles();

  const $s_unreadNotificationCount = useAppSelector(
    (state) => state.notification.unreadNotificationCount
  );
  const $s_authUser = useAppSelector((state) => state.auth.user);

  return $s_authUser?.user_role_id === authConstants.ROLE_PARENT_ID ? (
    <AppBadge badgeContent={$s_unreadNotificationCount} color="primary">
      <AppIconButton
        className={classes.notificationButton}
        component={AppLink}
        href="/notifications"
      >
        <AppSvgIcon
          className={classes.notificationButtonIcon}
          fontSize="inherit"
          component={NotificationIcon}
        />
      </AppIconButton>
    </AppBadge>
  ) : null;
};

export default NotificationDropdown;
