import { darken, rgbToHex } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles({
  name: "notificationDropdown",
})((theme) => {
  return {
    notificationButton: {
      backgroundColor: "rgba(235, 155, 194, 0.15)",
      "&:hover": {
        backgroundColor: darken(rgbToHex("rgba(235, 155, 194, 0.15)"), 0.3),
      },
    },
    notificationButtonIcon: {
      color: "#EB9BC2",
    },
  };
});

export default useStyles;
