import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles({
  name: "logoImages",
})(() => {
  return {
    root: {
      display: "flex",
    },
    logoImage: {
      width: "auto",
      height: "auto",
    },
  };
});

export default useStyles;
