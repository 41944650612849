import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles({
  name: "main",
})((theme) => {
  return {
    root: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: theme.palette.common.bg,
      minHeight: "100vh",
      // minWidth: theme.breakpoints.values.lg,
      overflow: "hidden",
    },
    main: {
      flexGrow: 1,
      width: "100%",
    },
  };
});

export default useStyles;
