import { commonConfig } from "@/utils/config";

import { Box, Typography } from "@mui/material";
import AppContainer from "@/components/AppContainer";
import AppButton from "@/components/AppButton";
import AppLink from "@/components/AppLink";
import AppImageV2 from "@/components/AppImageV2";

import { useTranslation } from "next-i18next";

import useStyles from "./NotFound404.styles";

const NotFound404 = () => {
  const { classes } = useStyles();

  const { t } = useTranslation();

  return (
    <AppContainer maxWidth="lg">
      <div className={classes.main}>
        <AppImageV2
          src="/images/svgs/error-404.svg"
          alt={`Error 404 | ${commonConfig.DOCUMENT_TITLE}`}
          width={200}
          height={200}
        />
        <Typography
          variant="nunitoExtraBold28"
          align="center"
          marginTop={5}
          marginBottom={5}
        >
          {t("Page not found")}
        </Typography>
        <Box display="flex" justifyContent="center">
          <AppButton
            variant="contained"
            color="blue"
            component={AppLink}
            href="/"
            sx={{ width: 360, maxWidth: "100%" }}
          >
            {t("Go to Home")}
          </AppButton>
        </Box>
      </div>
    </AppContainer>
  );
};

export default NotFound404;
