import { alpha, darken, rgbToHex } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles({
  name: "mainFooter",
})((theme, _, classes) => {
  return {
    root: {
      backgroundColor: theme.palette.common.white,
      minHeight: 414,
    },
    container: {
      position: "relative",
    },
    handDrawnBookSvg: {
      position: "absolute",
      top: 131,
      right: 36,
      width: 114,
    },
    handDrawnIdeaBulbSvg: {
      position: "absolute",
      bottom: 53,
      left: 191,
      height: 88,
    },
    content: {
      paddingTop: theme.spacing(7.25),
      paddingBottom: theme.spacing(7.25),
    },
    logoImg: {
      width: "auto",
      height: 148,
      marginBottom: theme.spacing(1),
      [theme.breakpoints.down("sm")]: {
        height: "auto",
        maxWidth: "100%",
      },
    },
    contactInfo: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-end",
      gap: theme.spacing(3.75),
      [theme.breakpoints.down("md")]: {
        flexWrap: "wrap",
        marginTop: theme.spacing(2.5),
      },
    },
    contactInfoList: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
      marginRight: theme.spacing(13.25),
    },
    contactInfoItem: {
      display: "flex",
      alignItems: "center",
      paddingLeft: theme.spacing(5),
      position: "relative",
      minHeight: 26,
    },
    contactInfoItemIcon: {
      position: "absolute",
      left: 0,
      top: 26 / 2,
      transform: "translateY(-50%)",
      fontSize: 24,
    },

    mediaSocial: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(5.5),
    },
    mediaSocialList: {
      display: "flex",
      gap: theme.spacing(2),
    },
    mediaSocialItemButton: {
      color: theme.palette.common.white,
      backgroundColor: alpha(theme.palette.common.orange, 0.15),
      "&:hover": {
        backgroundColor: darken(
          rgbToHex(alpha(theme.palette.common.orange, 0.15)),
          theme.palette.action.hoverOpacity
        ),
      },
    },
    mediaSocialItemButtonIcon: {
      color: theme.palette.common.orange,
      fontSize: 28,
    },

    allRightsReserved: {
      minHeight: 72,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  };
});

export default useStyles;
