import _keyBy from "lodash/keyBy";

import { contactConstants } from "@/utils/constants";

import { Link, Typography } from "@mui/material";
import AppContainer from "@/components/AppContainer";
import AppLink from "@/components/AppLink";
import AppIconButton from "@/components/AppIconButton";
import AppSvgIcon from "@/components/AppSvgIcon";
import LogoImage from "@/components/LogoImage";

import { ReactComponent as FacebookIcon } from "@/assets/svgs/icons/facebook.svg";
import { ReactComponent as InstagramIcon } from "@/assets/svgs/icons/instagram.svg";
import { ReactComponent as WhatsappIcon } from "@/assets/svgs/icons/whatsapp.svg";
import { ReactComponent as PhoneIcon } from "@/assets/svgs/icons/phone.svg";
import { ReactComponent as LocationIcon } from "@/assets/svgs/icons/location.svg";
import { ReactComponent as MailIcon } from "@/assets/svgs/icons/mail.svg";
import { ReactComponent as HandDrawnBookSvg } from "@/assets/svgs/hand-drawn-book.svg";
import { ReactComponent as HandDrawnIdeaBulbSvg } from "@/assets/svgs/hand-drawn-idea-bulb.svg";

import { useTranslation } from "next-i18next";
import { useAppSelector } from "@/hooks";
import { useMemo } from "react";

import useStyles from "./MainFooter.styles";
import { commonHelpers } from "@/utils/helpers";
import { commonConfig } from "@/utils/config";

const MainFooter = () => {
  const { classes } = useStyles();

  const curYear = new Date().getFullYear();

  const { t } = useTranslation();

  const $s_contacts = useAppSelector((state) => state.generalInfo.contacts);

  const idToContactMap = useMemo(() => {
    return _keyBy($s_contacts, "id");
  }, [$s_contacts]);

  return (
    <footer className={classes.root}>
      <AppContainer className={classes.container} maxWidth="lg">
        <HandDrawnBookSvg className={classes.handDrawnBookSvg} />
        <HandDrawnIdeaBulbSvg className={classes.handDrawnIdeaBulbSvg} />
        <div className={classes.content}>
          <LogoImage className={classes.logoImg} href="/" />
          <div className={classes.contactInfo}>
            <div className={classes.mediaSocial}>
              <div className={classes.mediaSocialList}>
                <AppIconButton
                  className={classes.mediaSocialItemButton}
                  component={Link}
                  underline="none"
                  target="_blank"
                  href={commonConfig.FACEBOOK_PAGE_LINK}
                >
                  <AppSvgIcon
                    className={classes.mediaSocialItemButtonIcon}
                    component={FacebookIcon}
                  />
                </AppIconButton>
                <AppIconButton
                  className={classes.mediaSocialItemButton}
                  component={Link}
                  underline="none"
                  target="_blank"
                  href={commonConfig.INSTAGRAM_PAGE_LINK}
                >
                  <AppSvgIcon
                    className={classes.mediaSocialItemButtonIcon}
                    component={InstagramIcon}
                  />
                </AppIconButton>
                <AppIconButton
                  className={classes.mediaSocialItemButton}
                  component={Link}
                  underline="none"
                  target="_blank"
                  href={`https://api.whatsapp.com/send?phone=${commonHelpers.formatPhoneNumberLink(
                    idToContactMap[contactConstants.CONTACT_WHATSAPP_ID]
                      ?.content
                  )}`}
                >
                  <AppSvgIcon
                    className={classes.mediaSocialItemButtonIcon}
                    component={WhatsappIcon}
                  />
                </AppIconButton>
              </div>
              <Typography
                variant="nunitoSemiBold16"
                component={AppLink}
                href="/privacy-policy"
                underline="hover"
              >
                {t("Privacy policy")}
              </Typography>
            </div>
            <div className={classes.contactInfoList}>
              <Typography
                variant="nunitoSemiBold16"
                className={classes.contactInfoItem}
                component={Link}
                underline="none"
                href={`tel:${commonHelpers.formatPhoneNumberLink(
                  idToContactMap[contactConstants.CONTACT_PHONE_ID]?.content
                )}`}
              >
                <AppSvgIcon
                  component={PhoneIcon}
                  className={classes.contactInfoItemIcon}
                  color="common.blue"
                />
                {idToContactMap[contactConstants.CONTACT_PHONE_ID]?.content}
              </Typography>

              <Typography
                variant="nunitoSemiBold16"
                className={classes.contactInfoItem}
                component={Link}
                underline="none"
                target="_blank"
                href={`https://api.whatsapp.com/send?phone=${commonHelpers.formatPhoneNumberLink(
                  idToContactMap[contactConstants.CONTACT_WHATSAPP_ID]?.content
                )}`}
              >
                <AppSvgIcon
                  component={WhatsappIcon}
                  className={classes.contactInfoItemIcon}
                  color="common.green"
                />
                {idToContactMap[contactConstants.CONTACT_WHATSAPP_ID]?.content}
              </Typography>

              <Typography
                component="div"
                variant="nunitoSemiBold16"
                className={classes.contactInfoItem}
                sx={(theme) => ({
                  maxWidth: 400,
                  [theme.breakpoints.down("md")]: {
                    maxWidth: "100%",
                  },
                })}
              >
                <AppSvgIcon
                  component={LocationIcon}
                  className={classes.contactInfoItemIcon}
                  color="common.orange"
                />
                {idToContactMap[contactConstants.CONTACT_ADDRESS_ID]?.content}
              </Typography>

              <Typography
                variant="nunitoSemiBold16"
                className={classes.contactInfoItem}
                component={Link}
                underline="none"
                href={`mailto:${
                  idToContactMap[contactConstants.CONTACT_MAIL_ID]?.content
                }`}
              >
                <AppSvgIcon
                  component={MailIcon}
                  className={classes.contactInfoItemIcon}
                  color="common.lightPink"
                />
                {idToContactMap[contactConstants.CONTACT_MAIL_ID]?.content}
              </Typography>
            </div>
          </div>
        </div>
        <Typography
          variant="nunitoReg13"
          color="common.blackGrey"
          className={classes.allRightsReserved}
        >
          {t(
            "Copyright © {{curYear}} Square To Limited. All rights reserved.",
            {
              curYear,
            }
          )}
        </Typography>
      </AppContainer>
    </footer>
  );
};

export default MainFooter;
