import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles({
  name: "appBadge",
})((theme) => {
  return {
    badge: {
      ...theme.typography.nunitoBold16,
      height: 24,
      minWidth: 24,
      borderRadius: 16,
    },
    anchorOriginTopRight: {
      transform: "translate(25%,-25%)",
    },
  };
});

export default useStyles;
