import _kebabCase from "lodash/kebabCase";

import { useEffect, useMemo } from "react";

import { authConstants } from "@/utils/constants";

import nextI18nextConfig from "@@/next-i18next.config";

import { CircularProgress } from "@mui/material";
import LayoutMain from "@/layouts/Main";
import ViewNotFound404 from "@/views/NotFound404";

import { useRouter } from "next/router";
import { useAppSelector, usePrevious } from "@/hooks";
import { useTranslation } from "next-i18next";

import useStyles from "./AuthGuard.styles";

type AuthGuardProps = {
  children?: any;
  disableRedirect?: boolean;
  permissionRoleId?: number;
};

const Redirect404 = () => {
  return (
    <LayoutMain>
      <ViewNotFound404 />
    </LayoutMain>
  );
};

const AuthGuard = (props: AuthGuardProps) => {
  const { permissionRoleId, disableRedirect, children } = props;

  const { i18n } = useTranslation();
  const { classes } = useStyles();
  const router = useRouter();
  const $s_hasAuth = useAppSelector((state) => !!state.auth.user?.id);
  const $s_authUserRoleId = useAppSelector(
    (state) => state.auth.user?.user_role_id
  );
  const $s_userAuthChecking = useAppSelector(
    (state) => state.auth.userAuthChecking
  );
  const prevAuthUserRoleId = usePrevious($s_authUserRoleId);

  const pathname =
    typeof window !== "undefined" ? window.location.pathname.toLowerCase() : "";

  const unAuthPaths = useMemo(() => {
    const pathResults: string[] = [];
    const locales = ["", ...nextI18nextConfig.i18n.locales];
    locales.forEach((locale) => {
      ["/sign-in", "/sign-up", "/password-reset"].forEach((path) => {
        pathResults.push(
          `${!!locale ? `/${locale.toLowerCase()}` : ""}${path}`
        );
      });
    });
    return pathResults;
  }, []);

  useEffect(() => {
    if (
      !$s_userAuthChecking &&
      !$s_hasAuth &&
      !unAuthPaths.includes(pathname) &&
      !disableRedirect
    ) {
      router.push(
        {
          pathname: "/sign-in",
          query: {
            ...router.query,
            redirect_path: window?.location?.pathname,
            role: `${_kebabCase(
              authConstants.roleLabelMap[prevAuthUserRoleId!]
            )}-${prevAuthUserRoleId!}`,
          },
        },
        undefined,
        {
          locale: i18n.language,
          shallow: true,
        }
      );
    } else if (
      unAuthPaths.includes(pathname) &&
      !$s_userAuthChecking &&
      $s_hasAuth
    ) {
      router.push(`/`);
    }
  }, [
    $s_hasAuth,
    $s_userAuthChecking,
    pathname,
    i18n.language,
    prevAuthUserRoleId,
  ]);

  if (
    [authConstants.ROLE_PARENT_ID, authConstants.ROLE_STAFF_ID].includes(
      permissionRoleId!
    ) &&
    !$s_userAuthChecking &&
    !!$s_hasAuth &&
    $s_authUserRoleId !== permissionRoleId
  )
    return <Redirect404 />;

  return $s_userAuthChecking ? (
    <div className={classes.loading}>
      <CircularProgress />
    </div>
  ) : unAuthPaths.includes(pathname) || disableRedirect || $s_hasAuth ? (
    children
  ) : null;
};

export default AuthGuard;
