import { forwardRef } from "react";

import { Icon, IconProps } from "@mui/material";

import useStyles from "./AppSvgIcon.styles";

type IconColor = "inherit" | "primary" | "default";

type CustomSvgIconProps<C extends string = IconColor> = {
  strokeColor?: boolean;
  color?: C;
};

export type AppSvgIconProps<
  D extends React.ElementType = "svg",
  P = {},
  C extends string = IconColor
> = CustomSvgIconProps<C> &
  Omit<IconProps<D, P>, keyof CustomSvgIconProps<C>> & {
    component?: D;
  };

const AppSvgIcon = <
  D extends React.ElementType = "svg",
  P = {},
  C extends string = IconColor
>(
  props: AppSvgIconProps<D, P, C>,
  ref: React.ForwardedRef<any>
) => {
  const { strokeColor, className, color = "inherit", ...rest } = props;

  const { classes, cx } = useStyles({ color });

  const appSvgIconClasses = cx(classes.root, classes.color, {
    [className as string]: !!className,
    [classes.strokeColor]: !!strokeColor,
  });

  return <Icon ref={ref} className={appSvgIconClasses} {...rest} />;
};

const AppSvgIconWithRef = forwardRef(AppSvgIcon);

export default AppSvgIconWithRef;
